export const AUTH = {
  LOGGED_IN: 'LOGGED_IN',
  IMAGINARY_CLIENT: 'IMAGINARY_CLIENT',
  CURRENT_IMAGINARY_USER: 'CURRENT_IMAGINARY_USER',
  USER: 'USER',
} as const

export const BUDGET_QUERY_KEYS = {
  BUDGETS: 'BUDGETS',
} as const

export const TAGS = {
  TAGS: 'TAGS',
} as const

export const EXPENSES = {
  EXPENSES: 'EXPENSES',
  WEEKLY_EXPENSES: 'WEEKLY_EXPENSES',
  MONTHLY_EXPENSES: 'MONTHLY_EXPENSES',
  DATE_RANGE: 'DATE_RANGE',
} as const

export const EXPENSES_SEARCH = {
  EXPENSES_SEARCH: 'EXPENSES_SEARCH',
} as const

export const INCOME_STATEMENT = {
  INCOME_STATEMENT: 'INCOME_STATEMENT',
  SOURCES: 'SOURCES',
  IS_EXPENSES: 'IS_EXPENSES',
  INCOME_TYPE: 'INCOME_TYPE',
} as const

export const BALANCE_SHEET = {
  ASSETS: 'ASSETS',
  LIABILITIES: 'LIABILITIES',
  LOAN_TRANSACTIONS: 'LOAN_TRANSACTIONS',
  LOANS_OVERVIEW: 'LOANS_OVERVIEW',
  INCOME_EXPENSES: 'INCOME_EXPENSES',
} as const

export const GOALS = {
  GOALS: 'GOALS',
  GOAL: 'GOAL',
  PROGRESS: 'PROGRESS',
  DETAILS: 'DETAILS',
  TIMELINE: 'TIMELINE',
  GOAL_PROPORTIONALITY_TYPES: 'GOAL_PROPORTIONALITY_TYPES',
  KPI_TYPES: 'KPI_TYPES',
} as const

export const CUSTOM_KPIS = {
  KPIS: 'CUSTOM_KPIS',
  DETAILS: 'CUSTOM_KPIS_DETAILS',
} as const

export const USER_CUSTOM_KPIS = {
  KPIS: 'USER_CUSTOM_KPIS',
  DETAILS: 'USER_CUSTOM_KPIS_DETAILS',
} as const

export const NOTIFICATION = {
  NOTIFICATION: 'NOTIFICATION',
} as const

export const LENDING = {
  TRANSACTIONS: 'LENDING_TRANSACTIONS',
  TRANSACTIONS_LIST: 'LENDING_TRANSACTIONS_LIST',
  ACCOUNTS: 'LENDING_ACCOUNTS',
} as const

export const SCENARIOS = {
  SCENARIOS: 'SCENARIOS',
}

export const COMPARISON = {
  COMPARISON: 'COMPARISON',
}
